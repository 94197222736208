import { createForm } from 'shared-ui/lib/customs/form/FormGenerator';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { vehicleInputs } from '../../inputs/vehicle.inputs';

const form: FormInput[] = [vehicleInputs.token];

export const findVehicleForm = createForm(form);

export type FindVehicleFormBody = {
  token: string;
};
