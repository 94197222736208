import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const title: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'title',
    type: 'text',
    label: 'Reminder:title',
  },
};

const date: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'date',
    type: 'date',
    label: 'Reminder:date',
  },
};

export const reminderInputs = {
  title,
  date,
};
