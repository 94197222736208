import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import { YupNullableNumber } from 'shared-ui/lib/utils/yup-nullable-number';
import * as Yup from 'yup';
import { getVehicleTypeOptions } from '../data/get-vehicle-type-options';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'name',
    type: 'text',
    label: 'Vehicle:name',
  },
};

const token: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'token',
    type: 'text',
    label: 'Vehicle:token',
  },
};

const registerNumber: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'registerNumber',
    type: 'text',
    label: 'Vehicle:registerNumber',
  },
};

const type: FormInput = {
  type: 'select',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    placeholder: 'Vehicle:choose',
    name: 'type',
    label: 'Vehicle:type',
    options: getVehicleTypeOptions(),
  },
};

const VIN: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'VIN',
    type: 'text',
    label: 'Vehicle:VIN',
  },
};

const mileage: FormInput = {
  type: 'text',
  initValue: '',
  validation: YupNullableNumber,
  props: {
    name: 'mileage',
    type: 'number',
    label: 'Vehicle:mileage',
  },
};

const runningHours: FormInput = {
  type: 'text',
  initValue: '',
  validation: YupNullableNumber,
  props: {
    name: 'runningHours',
    type: 'number',
    label: 'Vehicle:runningHours',
  },
};

const brand: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'brand',
    type: 'text',
    label: 'Vehicle:brand',
  },
};

const model: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'model',
    type: 'text',
    label: 'Vehicle:model',
  },
};

const description: FormInput = {
  type: 'textarea',
  initValue: '',
  validation: Yup.string(),
  props: {
    name: 'description',
    label: 'Vehicle:description',
  },
};

const commissioning: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .nullable()
    .transform((_, v) => (v === '' ? null : v)),
  props: {
    name: 'commissioning',
    type: 'date',
    label: 'Vehicle:commissioning',
  },
};

const warranty: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string()
    .nullable()
    .transform((_, v) => (v === '' ? null : v)),
  props: {
    name: 'warranty',
    type: 'date',
    label: 'Vehicle:warranty',
  },
};

const modelYear: FormInput = {
  type: 'text',
  initValue: '',
  validation: YupNullableNumber,
  props: {
    name: 'modelYear',
    type: 'number',
    label: 'Vehicle:modelYear',
  },
};

const photo: FormInput = {
  type: 'file',
  initValue: null,
  props: {
    name: 'photo',
    label: 'Vehicle:picture',
  },
};

export const vehicleInputs = {
  name,
  registerNumber,
  type,
  VIN,
  token,
  photo,
  mileage,
  runningHours,
  brand,
  model,
  description,
  commissioning,
  warranty,
  modelYear,
};
