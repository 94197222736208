import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'Business:name',
    type: 'text',
    autoComplete: 'name',
  },
};

const photo: FormInput = {
  type: 'file',
  initValue: null,
  props: {
    name: 'photo',
    label: 'Business:picture',
  },
};

export const partnerInput = {
  name,
  photo,
};
