import { t } from 'i18next';
import { FormInput } from 'shared-ui/lib/customs/form/FormInput';
import * as Yup from 'yup';

const email: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().email().required(),
  props: {
    name: 'email',
    label: 'User:email',
    type: 'email',
    autoComplete: 'email',
  },
};

const name: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'name',
    label: 'User:name',
    type: 'text',
    autoComplete: 'name',
  },
};

const partnerName: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().required(),
  props: {
    name: 'partnerName',
    label: 'User:partnerName',
    type: 'text',
  },
};

const password: FormInput = {
  type: 'text',
  initValue: '',
  validation: Yup.string().min(8).required(),
  props: {
    name: 'password',
    label: 'User:password',
    type: 'password',
    autoComplete: 'current-password',
  },
};

const terms: FormInput = {
  type: 'element',
  props: {
    type: 'element',
    name: 'terms',
    Element: () => (
      <div className="text-primary hover:text-secondary mt-3 text-xs">
        <a target="__black" href="https://ajoneuvoni.fi/kayttoehdot/">
          {t('User:terms')}
        </a>
      </div>
    ),
  },
};

const isAccepted: FormInput = {
  type: 'checkbox',
  initValue: false,
  validation: Yup.boolean().required(),
  props: {
    name: 'isAccepted',
    label: 'User:isAccepted',
  },
};

export const userInputs = {
  email,
  name,
  password,
  partnerName,
  terms,
  isAccepted,
};
